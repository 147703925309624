import React from 'react';
import Helmet from 'react-helmet';
import { Header, Container, Layout } from 'components';
import config from '../../config/website';

const Contact = () => (
  <Layout>
    <Helmet title={`Contact | ${config.siteTitle}`} />
    <Header>Contact</Header>
    <Container type="text">
      <h1>Reach out to us</h1>
      <p>We may not be <em>Juris Doctors</em>, but we're probably <em>doctors</em> of something.</p>
      <p>
        mark@amicusbeef.com
      </p>
    </Container>
  </Layout>
);

export default Contact;
