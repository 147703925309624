import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'react-emotion';
import Img from 'gatsby-image';
import sample from 'lodash/sample';
import { overlay } from '../../config/theme';

// import { Flex } from '@rebass/grid/emotion'

import config from '../../config/website';


const Hero = styled.div`
  height: 100%;
  text-align: center;
  padding: 5rem 0 5rem 0;
  h1 {
    letter-spacing: 0.2rem;
    line-height: 4.5rem;
  }
  h3 {
    font-family: ${props => config.headerFontFamily};
    margin-top: 1rem;
    font-size: 1.15rem;
    font-weight: 400;
  }
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    h1 {
      line-height: 3.5rem;
    }
    h3 {
      font-size: 1.5rem;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    h1 {
      line-height: 2.5rem;
    }
    h3 {
      font-size: 1.3rem;
    }
  }
`;

const SubWrapper = styled.div`
  padding: 1rem 0;
`;


const Banner = () => (
  <Hero justifyContent="center" alignItems="center" flexDirection="column">
    <h1>
      { config.heroTitle }
    </h1>
    <SubWrapper>
      <h3>{ config.heroSubTitle_1 }</h3>
      <h3>{ config.heroSubTitle_2 }</h3>
    </SubWrapper>
  </Hero>
);




export default Banner;